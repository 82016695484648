<template>
  <div>
    <div class="px-3 py-3 pt-lg-5 pb-lg-4 text-center" ref="packageSelect">
      <div class="sec-head font-weight-bolder">Let's get started by choosing your package</div>
    </div>
    <form class="row container justify-content-center text-center" ref="selection">
      <div class="col-lg-4 mb-3">
        <label for="state" class="font-weight-bolder required-star sec-subsubhead">State</label>
        <select class="custom-select d-block w-100 clickable" id="state" v-model="state" required>
          <Option value>{{ $t('message.choose') }}...</Option>
          <Option value="CA">CA</Option>
          <Option value="DE">DE</Option>
        </select>
        <div class="invalid-feedback">Please provide a valid state.</div>
      </div>
      <div class="col-lg-4 mb-3">
        <label for="state" class="font-weight-bolder required-star sec-subsubhead">Entity Type</label>
        <select class="custom-select d-block w-100 clickable" id="type" v-model="type" required>
          <option value>{{ $t('message.choose') }}...</option>
          <Option value="LLC">LLC</Option>
          <Option value="CCORP">C-Corporation</Option>
        </select>
        <div class="invalid-feedback">Please provide a valid company type.</div>
      </div>
    </form>
    <div class="pricing-header px-3 py-3 pt-lg-5 pb-lg-4 mx-auto text-center">
      <div class="sec-head font-weight-bolder">Pricing</div>
    </div>
    <div class="container">
      <div class="card-deck mb-3 text-center">
        <Price
          package-name="Basic"
          default-fee="348"
          class="mx-2"
          :state="state"
          :type="type"
          package="BASIC"
          @validate="validate"
        >
          <ul class="packageItem mt-3 mb-4">
            <li>Corporation Certificate (Electronic Delivery)</li>
            <li>Company Name Search</li>
            <li>Federal Employment Identification Number</li>
            <li>One Year Registered Agent Services Included ($99/year starting from the 2nd year)</li>
            <li>Initial State of Information</li>
            <li>Simple Company Bylaw</li>
            <li>Business Bank Account Setup(Additional fee may apply)</li>
          </ul>
        </Price>
        <Price
          package-name="Starter"
          default-fee="998"
          class="mx-2"
          :state="state"
          :type="type"
          package="PREMIUM"
          @validate="validate"
        >
          <ul class="packageItem mt-3 mb-4">
            <li>Corporation Certificate</li>
            <li>Company Name Search</li>
            <li>Federal Employment Identification Number</li>
            <li>One Year Registered Agent Services Included ($99/year starting from the 2nd year)</li>
            <li>Initial State of Information</li>
            <li>Simple Founder Stock Purchase Agreement</li>
            <li>Simple Company Bylaw</li>
            <li>Employee NDA</li>
            <li>Employee Handbook</li>
            <li>Offer Letter</li>
            <li>Business Bank Account Setup(Additional fee may apply)</li>
          </ul>
        </Price>
        <Price
          package-name="Elite"
          default-fee="2998"
          class="mx-2"
          :state="state"
          :type="type"
          package="CUSTOM"
          @validate="validate"
        >
          <ul class="packageItem mt-3 mb-4">
            <li>Corporate Certification</li>
            <li>Company Name Search</li>
            <li>Federal Employment Identification Number</li>
            <li>One Year Registered Agent Services Included ($99/year starting from the 2nd year)</li>
            <li>State Employment Identification Number</li>
            <li>Founder Stock Purchase Agreement</li>
            <li>Company Bylaw</li>
            <li>Employee Stock Option Plan</li>
            <li>Employee NDA</li>
            <li>Offer Letter</li>
            <li>Employee Handbook</li>
            <li>Various contract templates</li>
            <li>Company Financing Consultation</li>
          </ul>
        </Price>
      </div>
    </div>
  </div>
</template>

<script>
import Price from '@/components/Price'
import jq from 'jquery'

export default {
  name: 'Pricing',
  data() {
    return {
      state: '',
      type: '',
    }
  },
  components: { Price },
  created() {
    if (this.$route.query.state !== undefined) {
      localStorage.state = this.$route.query.state
    }
    if (this.$route.query.type !== undefined) {
      localStorage.type = this.$route.query.type
    }
    if (localStorage.state) {
      this.state = localStorage.state
    }
    if (localStorage.type) {
      this.type = localStorage.type
    }
    window.scrollTo(0, 0)
  },
  methods: {
    validate() {
      this.$refs.selection.classList.add('was-validated')
      window.scrollTo(0, jq('.was-validated :invalid').offset().top)
    },
  },
}
</script>

<style scoped lang="scss">
.card-header {
  background-color: rgba(54, 77, 110, 1);
  color: white;
}

.required-star::after {
  content: ' *';
  color: red;
}

.text-muted {
  color: white !important;
}

.yellow {
  color: rgba(242, 204, 79, 1);
}

.yellowBtn {
  color: rgba(54, 77, 110, 1);
  background-color: rgba(242, 204, 79, 1);
  border: none;
}

.packageItem {
  padding: 10px 20px;
  text-align: left;
}

.row {
  margin: 20px auto;
}

.text-muted {
  font-size: 65%;
}

.sec-head {
  font-size: 1.5rem;
}

.sec-subhead {
  font-size: 1.125rem;
}

@include media-breakpoint-up(lg) {
  .sec-head {
    font-size: 2.625rem;
  }

  .sec-subhead {
    font-size: 2rem;
  }

  .sec-subsubhead {
    font-size: 1.625rem;
  }
}

.card {
  border-radius: 12px;
}

.card-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
</style>
